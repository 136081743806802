import Button from "../button/button";
import "./presentation.css";
import informa1 from "./presentationIMG/informa-2.webp";
import informa2 from "./presentationIMG/informa-1.webp";
import Timer from "./timer/timer";
import { Link } from "react-router-dom";

const Presentation = () => {
  return (
    <section className="presentation__event">
      <div className="presentation__imgs-container">
        <img src={informa1} alt="Porche" className="presentation__img lazy" />
        <img src={informa2} alt="informa2" className="presentation__img" />
      </div>
      <div className="presentation__text">
        <div className="buttons__container buttonmovil">
          <Button text={"Evento finalizado"} />
        </div>
        <h2 className="presentation__title"> OBJETIVO DEL EVENTO</h2>
        <h3 className="presentation__slogan">
          Promover la inclusión y la diversidad de todas las personas,
          fomentando la actividad física y los hábitos de vida saludable a
          través de la participación en diferentes actividades deportivas y
          recreativas para todas las edades, así como talleres donde los más
          jóvenes podrán aprender y mejorar sus habilidades en la nutrición.
        </h3>
        <p>
          Impulsar el deporte lúdico, promocionando el fitness y creando un
          ambiente familiar y solidario. Sintiéndote en todo momento parte de{" "}
          <span className="resalta"> FIT EXPERIENCE FEST</span>.
        </p>
        <h1 className="presentation__title">
          FIT Experience FEST <span className="presentation__year">2024</span>
        </h1>

        <div className="buttons__container">
          <Button text={"Evento finalizado"} />

          <Link
            to={"/Fit-Experience-Fest.pdf"}
            target="blank"
            className="masinfo"
          >
            <Button text={"Mas información"} />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Presentation;
