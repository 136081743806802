import "./hoteles.css";
import Button from "../button/button";
import { Link } from "react-router-dom";
import Obfuscate from 'react-obfuscate';

const Hoteles = () => {
  return (
    <section className="hoteles">
      <figure>
        <span>
          <img
            src="../fit-logo.svg"
            title="Fit Experience Fest"
            alt="Fit Experience Fest"
          />
        </span>
      </figure>
      <div className="titulohoteles">
        <div className="information__titles">
          <h2>
            <span> Alojamientos </span>que colaboran con nosotros
          </h2>
        </div>
        <p className="info__description">
          No pierdas la oportunidad de beneficiarte de los descuentos exclusivos
          que nuestros hoteles colaboradores ofrecen para el evento. Reserva tu
          estancia en uno de los siguientes hoteles y utiliza los códigos
          promocionales indicados para disfrutar de una experiencia inolvidable
          a un precio especial. ¡Haz tu reserva ahora y aprovecha estas
          increíbles ofertas!
        </p>
      </div>
      <div className="columnashoteles">
        <div className="hotel_urban_anaga">
          <div className="information__titles">
            <h2>
              Hotel<span> Urban Anaga</span>
            </h2>
          </div>
          <div className="textohotel">
            <p>
              C&oacute;digo promocional{" "}
              <span className="codigo">EXPERIENCE24</span> <br />
              <span className="muto">
                introducir al momento de hacer la reserva
              </span>
            </p>
            <div className="InfoButton__container">
              <Link target="_blank" rel="nofollow" to={"https://urbananagahotel.com/"}>
                <Button text={"Reservar"} />
              </Link>
            </div>
          </div>
        </div>
        <div className="hotel_nh_tenerife">
          <div className="information__titles">
            <h2>
              Hotel <span> NH Tenerife</span>
            </h2>
          </div>
          <div className="textohotel">
            <p>
              C&oacute;digo promocional{" "}
              <span className="codigo">Experience</span> <br />
              <span className="muto">facilitar al formalizar la reserva</span>
            </p>
            <div className="InfoButton__container">
            <Obfuscate email="nhtenerife@nh-hotels.com">
                <Button text={"Reservar"} />
              </Obfuscate>
            </div>
          </div>
        </div>
        <div className="blue_sea_puerto_de_la_cruz">
          <div className="information__titles">
            <h2>
              Blue Sea <span> Puerto de la Cruz</span>
            </h2>
          </div>
          <div className="textohotel">
            <p>
              C&oacute;digo promocional{" "}
              <span className="codigo">EXPERIENCIACANARIAS </span> <br />
              <span className="muto">todo en mayúsculas y junto</span>
            </p>
            <div className="InfoButton__container">
              <Link target="_blank"
                rel="nofollow"
                to={
                  "https://www.blueseahotels.com/hoteles/destinos/tenerife/puerto-de-la-cruz/bluesea-costa-jardin-spa"
                }
              >
                <Button text={"Reservar"} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hoteles;
