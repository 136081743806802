import "./condiciones.css";

const condiciones = () => {
  return (
    <section className="condiciones">
    <div className="texto">
      <div className="titulo">
      <h1>Condiciones generales</h1>
      </div>
      <div className="container">
      <ol>
<li ><strong>La entrada es v&aacute;lida &uacute;nicamente para el d&iacute;a indicado.</strong> No se admiten cambios ni devoluciones. Cada entrada est&aacute; identificada con un c&oacute;digo &ldquo;QR&rdquo; &uacute;nico que se debe preservar para que no sea copiado. Evita exponerlo, as&iacute; como subir fotos sin cubrirlo, ya que s&oacute;lo podr&aacute; pasar una vez por nuestro control de acceso. Si compraste una entrada on line para zona VIP, debes asegurarte de que accedes al lugar correcto. Es tu responsabilidad que te pongan la pulsera correspondiente a esa zona seg&uacute;n el tipo de entrada que has comprado. F&iacute;jate en las indicaciones y si tienes alguna duda, pregunta a nuestro personal. Una vez accedas, si te ponen una pulsera incorrecta de otra zona, ya no podr&aacute;s volver atr&aacute;s y cambiarla.</li>
<li ><strong>EST&Aacute; RESERVADO EL DERECHO DE ADMISI&Oacute;N POR RAZONES DE SEGURIDAD Y ORDEN P&Uacute;BLICO</strong>. En ning&uacute;n caso, conllevar&aacute; discriminaci&oacute;n por raz&oacute;n de nacimiento, raza, sexo, religi&oacute;n, opini&oacute;n, discapacidad, orientaci&oacute;n sexual, identidad de g&eacute;nero o cualquier otra condici&oacute;n o circunstancia personal o social. El acceso s&oacute;lo se permitir&aacute; con la entrada completa. Toda entrada rota, en mal estado, enmendada o con signos de falsificaci&oacute;n autorizar&aacute; a la organizaci&oacute;n a prohibir a su portador el acceso al recinto sin derecho a devoluci&oacute;n del importe correspondiente. En el caso de pulseras deterioradas o que no puedan ponerse o cerrarse por cualquier causa, siempre que conserven su c&oacute;digo QR intacto, podr&aacute;n cambiarse por una nueva en la puerta de acceso, con un coste de 5&euro;.</li>
<li ><strong>Es imprescindible presentar el DNI, carn&eacute; de conducir o pasaporte, siempre en formato ORIGINAL</strong>, con foto reciente. No se aceptar&aacute;n fotocopias, denuncias por robo o extrav&iacute;o, ni documentos que contengan tachaduras o signos de manipulaci&oacute;n.&nbsp;</li>
<li >En protecci&oacute;n de los derechos de imagen y propiedad intelectual, <strong>queda terminantemente prohibido filmar o grabar la totalidad o parte del Festival mediante v&iacute;deo, audio o por cualquier otro medio</strong>, as&iacute; como el uso de c&aacute;maras fotogr&aacute;ficas PROFESIONALES.</li>
<li ><strong>Se proh&iacute;be la entrada con maletas, maletines, bolsos de mano o mochilas grandes, tambi&eacute;n con botellas, latas, paraguas, armas de toda clase o cualquier objeto que la organizaci&oacute;n considere peligroso</strong>. Se proh&iacute;be tambi&eacute;n el acceso a todas las personas que manifiesten conductas violentas e impropias o s&iacute;ntomas de estar bajo los efectos de bebidas alcoh&oacute;licas y/o sustancias estupefacientes.&nbsp;</li>
<li >El acceso al recinto est&aacute; sujeto a un registro y verificaci&oacute;n de edad conforme a la Ley. A quienes no acrediten su identidad mediante documento oficial original, o&nbsp;<strong>no presenten la autorizaci&oacute;n exigida en el caso de menores de edad</strong>&nbsp;o se nieguen al registro, se les impedir&aacute; el acceso al recinto.&nbsp;</li>
<li ><strong>El poseedor de una entrada reconoce que su imagen podr&aacute; aparecer en tomas de v&iacute;deo o fotogr&aacute;ficas realizadas en el recinto por diferentes medios para su posterior difusi&oacute;n informativa y/o promocional, y acepta y presta su conformidad para dichos usos</strong>.</li>
<li ><strong>La empresa organizadora del Festival se reserva el derecho de alterar o cambiar, en cualquier momento, sin previo aviso o condici&oacute;n</strong>, tanto el Recinto de celebraci&oacute;n como el horario y/o programa establecido, incluido el line up, por motivos organizativos justificados, o de ausencia de cualquiera de los atletas invitados, as&iacute; como artistas, debido a causas sobrevenidas, tales como enfermedad, accidente, p&eacute;rdida de documentaci&oacute;n legal, vuelo u otro medio de transporte y cualquier otra causa objetiva que se considere. Dichos cambios no conllevan devoluci&oacute;n del importe de las entradas.&nbsp;</li>
<li ><strong>La organizaci&oacute;n no garantiza la autenticidad y/o validez de una entrada si no ha sido adquirida a trav&eacute;s de los canales oficiales de venta</strong>. La posesi&oacute;n de una entrada falsificada, adem&aacute;s de no facilitar el acceso al recinto, generar&aacute; las acciones legales pertinentes. Cada c&oacute;digo QR es &uacute;nico, no acudas al festival con una entrada a nombre de otra persona salvo que sea de tu absoluta confianza, NI COMPRES TU ENTRADA A CUALQUIER PERSONA DESCONOCIDA.</li>
<li ><strong>El poseedor de una entrada perder&aacute; sus derechos al salir del recinto</strong>, ya sea voluntariamente, por desalojo generalizado de la autoridad competente o por indicaciones del personal autorizado, por motivos de seguridad, desorden o mala conducta, responsabiliz&aacute;ndose el portador de sus propias acciones y omisiones que causen lesiones a terceros o da&ntilde;os a bienes. En ning&uacute;n caso se tendr&aacute; derecho a la devoluci&oacute;n del importe de las entradas.&nbsp;</li>
<li ><strong>La cancelaci&oacute;n del evento por Orden y/o recomendaci&oacute;n de las autoridades competentes como consecuencia de fen&oacute;menos clim&aacute;ticos adversos o acontecimientos sobrevenidos de &iacute;ndole natural, epidemiol&oacute;gicos, cierre del espacio a&eacute;reo</strong> o cualesquiera otros que se consideren objetivamente peligrosos o supongan potencialmente riesgo para los asistentes y para la propia Organizaci&oacute;n, no conllevar&aacute; necesariamente la devoluci&oacute;n del importe de las entradas, siempre que se anuncie una nueva fecha para la celebraci&oacute;n del mismo con id&eacute;nticas o similares condiciones de servicio.</li>
<li ><strong>Si la fecha del evento variara por cualquier motivo justificado, la entrada ya adquirida ser&aacute; autom&aacute;ticamente v&aacute;lida para la fecha definitiva.</strong> En este supuesto no se podr&aacute; reclamar la devoluci&oacute;n del importe de la misma.</li>
<li ><strong>La empresa Promotora GARANTIZA el reembolso del importe nominal de las entradas</strong> (descontados los gastos de gesti&oacute;n, comisiones, etc, que como norma general se establece en un m&iacute;nimo del 10% o, en su caso, en un rango de entre 5&euro; y 10&euro;), si el evento fuera cancelado por causas imputables a la Empresa, estableci&eacute;ndose para ello un plazo m&aacute;ximo razonable que sea suficiente para la organizaci&oacute;n y desarrollo de tales acciones en los puntos de venta. Los gastos que se hubieran podido ocasionar por transporte, desplazamientos, reservas de hoteles, apartamentos y cualesquiera otros que pudieran aducirse como derivados del evento cancelado no podr&aacute;n ser reclamados a la empresa ni ser&aacute;n tenidos en cuenta.</li>
<li ><strong>Est&aacute; PROHIBIDA la reventa de entradas</strong>, aunque fuera para la utilizaci&oacute;n de la misma con fines promocionales o sociales, sin el consentimiento expreso y escrito de los promotores del evento.&nbsp;</li>
<li >En los Festivales &lsquo;multi stage&rsquo;, las diferentes &aacute;reas de cada una de las zonas del Festival podr&aacute;n tener, por razones obvias de espacio, un aforo limitado, pudi&eacute;ndose cortar el acceso a dichas zonas, por seguridad, una vez alcanzado dicho aforo. En consecuencia, la Organizaci&oacute;n no ser&aacute; en ning&uacute;n caso responsable de la imposibilidad de ciertos asistentes de acudir a determinados eventos que integren el Festival.</li>
<li >Los vasos para el consumo de bebidas dentro del Festival cuestan, con car&aacute;cter general 1&euro;. Puedes adquirirlo al comprar tu entrada (recomendable) o bien tu moneda preparada, la empresa no est&aacute; obligada a proporcionarte cambio si pagas con billetes de m&aacute;s de 20&euro;.</li>
<li >La venta de bebidas alcoh&oacute;licas tendr&aacute; una hora l&iacute;mite para ultimas consumiciones, es de 20min antes del horario previsto para el cierre (habitualmente, las 22:40h).</li>
<li >El portador de la entrada, sea en papel o pulsera, declara expresamente que ha le&iacute;do, comprendido y, por tanto, acepta estas CONDICIONES y asimismo manifiesta que es totalmente consciente de los derechos y obligaciones que de ellas emanan.</li>
</ol>
      </div>
     
    </div>
  </section>
  );
};

export default condiciones;
