import React from "react";
import "./reset.css"
import Home from "./pages/Home";
import Page404 from "./pages/page404/page404";
import Condiciones from "./pages/condiciones/condiciones";
import Aviso from "./pages/aviso/aviso";
import Header from "./components/header/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/footer/footer";
import InfoDev from "./components/footer/icons-socials/infoDEV/infoDEV";
import HeaderMobile from "./components/headerMobile/headerMobile";

function App({qrData}) {
  return (
    <Router>
      <Header/>
      <HeaderMobile/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/Condiciones" element={<Condiciones/>}/>
        <Route path="/Aviso" element={<Aviso/>}/>
        <Route path="*" element={<Page404 />} />       
      </Routes>
      <Footer/>
      <InfoDev/>
    </Router>
  )
}

export default App;

