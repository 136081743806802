import "./timeline.css";
import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// Define TabPanel and a11yProps
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={{ color: "#fff" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      className="retoss"
      sx={{
        flexGrow: 1,
        color: "#fff",
        bgcolor: "transparent",
        display: "flex",
        height: 400,
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderRight: 1,
          color: "#fff",
          fontSize: 22,
          fontFamily: "Staatliches",
          borderColor: "divider",
          "& .MuiTabs-indicator": {
            backgroundColor: "hsl(50, 70%, 50%)",
          },
        }}
        style={{ width: "30%" }}
      >
        <Tab
          label="Zona Fisiculturismo"
          {...a11yProps(0)}
          sx={{
            color: "#fff",
            fontSize: 22,
            fontFamily: "Staatliches",
            "&.Mui-selected": {
              color: "hsl(50, 70%, 50%)",
            },
          }}
        />
        <Tab
          label="Zona CrossFit"
          {...a11yProps(1)}
          sx={{
            color: "#fff",
            fontSize: 22,
            fontFamily: "Staatliches",
            "&.Mui-selected": {
              color: "hsl(50, 70%, 50%)",
            },
          }}
        />
        <Tab
          label="Zona Powerlifting"
          {...a11yProps(2)}
          sx={{
            color: "#fff",
            fontSize: 22,
            fontFamily: "Staatliches",
            "&.Mui-selected": {
              color: "hsl(50, 70%, 50%)",
            },
          }}
        />
        <Tab
          label="Zona Artes Marciales"
          {...a11yProps(3)}
          sx={{
            color: "#fff",
            fontSize: 22,
            fontFamily: "Staatliches",
            "&.Mui-selected": {
              color: "hsl(50, 70%, 50%)",
            },
          }}
        />
        <Tab
          label="Zona Natación"
          {...a11yProps(4)}
          sx={{
            color: "#fff",
            fontSize: 22,
            fontFamily: "Staatliches",
            "&.Mui-selected": {
              color: "hsl(50, 70%, 50%)",
            },
          }}
        />
        <Tab
          label="Zona Life Pro"
          {...a11yProps(5)}
          sx={{
            color: "#fff",
            fontSize: 22,
            fontFamily: "Staatliches",
            "&.Mui-selected": {
              color: "hsl(50, 70%, 50%)",
            },
          }}
        />
      </Tabs>
      <TabPanel value={value} index={0} style={{ width: "70%" }}>
        <ul>
          <li>
            <strong>Prensa Car:</strong> Consistir&aacute; en levantar un
            veh&iacute;culo de unos 400kg en una prensa.
          </li>
          <li>
            <strong>Press Influencer:</strong> Consistir&aacute; en levantar a
            dos influencers, en la barra de press de banca plano.
          </li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={1} style={{ width: "70%" }}>
        <ul>
          <li>
            <strong>Dominadas:</strong> Consistir&aacute; en realizar el mayor
            n&uacute;mero de flexiones de brazos en la barra.
          </li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={2} style={{ width: "70%" }}>
        <ul>
          <li>
            <strong>Thor 100K:</strong> Consistir&aacute; en levantar una
            mancuerna de 100kg con un brazo el mayor n&uacute;mero de veces.
          </li>
          <li>
            <strong>Super Power:</strong> Consistir&aacute; en ejecutar 1
            sentadilla libre con el mayor peso.
          </li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={3} style={{ width: "70%" }}>
        <ul>
          <li>
            <strong>Super Pump:</strong> Consistir&aacute; en golpear una pera
            de boxeo alcanzando la mayor marca de fuerza.
          </li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={4} style={{ width: "70%" }}>
        <ul>
          <li>
            <strong>Gran Phelps:</strong> Consistir&aacute; en una carrera de
            velocidad a lo largo de la piscina.
          </li>
        </ul>
      </TabPanel>
      <TabPanel value={value} index={5} style={{ width: "70%" }}>
        <ul>
          <li>
            <strong>Batido Express:</strong> Consistir&aacute; en tomar el mayor
            n&uacute;mero de batidos en 1 minuto.
          </li>
        </ul>
      </TabPanel>
    </Box>
  );
}

// Hora color variable
const hora = "hsl(50, 70%, 50%)";

const Timelines = () => {
  return (
    <section className="timeline" id="timeline">
      <div className="stanga">
        <div className="information__titles mijloc mb-2">
          <h2>
            <span>HORARIO</span>
          </h2>
        </div>
        <Timeline position="alternate" className="tiempo">
          <TimelineItem>
            <TimelineOppositeContent className="horas" color={hora}>
              14:00 - 15:00 <br />
              <strong>INICIO</strong>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className="contenidoevento">
              <ul>
                <li>Regalo de bienvenida</li>
                <li>Photocall</li>
                <li>Vehículos deportivos</li>
                <li>Recorrido por los diferentes stand y talleres.</li>
                <li>Conocer la marca patrocinadora</li>
                <li>Atracciones</li>
                <li>Foodtrucks</li>
              </ul>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent color={hora}>
              15:00 - 18:00 <br />
              <strong>RETOS</strong>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className="contenidoevento">
              <p>
                Pruebas en las diferentes disciplinas deportivas ubicadas en el
                evento como:
              </p>
              <ul>
                <li>Fisioculturismo</li>
                <li>Cross fit</li>
                <li>Power lifting</li>
                <li>MMA-Kick Boxing</li>
                <li>Lucha canaria</li>
                <li>Natación</li>
                <li>Futbol</li>
              </ul>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent color={hora}>
              18:00 - 19:00 <br />
              <strong>EXPERIENCE</strong>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className="contenidoevento">
              <p>
                Los asistentes podrán conocer a sus referentes e
                influenciadores. Y brindaremos por una comunidad fitness unidad.
              </p>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent color={hora}>
              19:00 - 23:00 <br />
              <strong>CIERRE</strong>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className="contenidoevento">
              <p>
                Entrada del Grupo Coreográfico acompañado por nuestros Djs para
                dar comienzo a nuestro festival deportivo.
              </p>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </div>
      <div className="dreapta">
        <div className="information__titles mijloc mb-2">
          <h2>Retos Deportivos</h2>
        </div>
        <VerticalTabs />
        <img
          className="imgris"
          src="../../../img/patrocinadores_timeline.svg"
          alt="Patrocinadores"
        />
      </div>
    </section>
  );
};

export default Timelines;
