import "./sponsors.css";
import patro1 from "./sponsorsLOGOS/patro-1.png"
import patro2 from "./sponsorsLOGOS/patro-2.png"
import patro4 from "./sponsorsLOGOS/patro-4.png"
import patro5 from "./sponsorsLOGOS/patro-5.png"
import patro6 from "./sponsorsLOGOS/patro-6.png"
import patro7 from "./sponsorsLOGOS/patro-7.png"
import patro8 from "./sponsorsLOGOS/patro-8.png"
import patro9 from "./sponsorsLOGOS/patro-9.png"
import patro10 from "./sponsorsLOGOS/patro-10.png"
import patro11 from "./sponsorsLOGOS/patro-11.png"
import patro12 from "./sponsorsLOGOS/patro-12.png"
import patro13 from "./sponsorsLOGOS/patro-13.png"
import patro14 from "./sponsorsLOGOS/patro-14.png"
import patro15 from "./sponsorsLOGOS/patro-15.png"
import patro16 from "./sponsorsLOGOS/patro-16.png"
import patro17 from "./sponsorsLOGOS/patro-17.png"
import patro18 from "./sponsorsLOGOS/patro-18.png"
import patro19 from "./sponsorsLOGOS/patro-19.png"
import patro20 from "./sponsorsLOGOS/patro-20.png"
import patro21 from "./sponsorsLOGOS/patro-21.png"
import patro22 from "./sponsorsLOGOS/patro-22.png"
import patro23 from "./sponsorsLOGOS/patro-23.png"
import patro25 from "./sponsorsLOGOS/patro-25.png"
import patro26 from "./sponsorsLOGOS/patro-26.png"
import patro27 from "./sponsorsLOGOS/patro-27.png"
import patro28 from "./sponsorsLOGOS/patro-28.png"
import patro29 from "./sponsorsLOGOS/patro-29.png"
import patro30 from "./sponsorsLOGOS/patro-30.png"
import patro31 from "./sponsorsLOGOS/patro-31.png"
import patro32 from "./sponsorsLOGOS/patro-32.png"
import patro33 from "./sponsorsLOGOS/patro-33.png"
import patro34 from "./sponsorsLOGOS/patro-34.png"
import patro35 from "./sponsorsLOGOS/patro-35.png"
import patro36 from "./sponsorsLOGOS/patro-36.png"
import patro37 from "./sponsorsLOGOS/patro-37.png"
import patro38 from "./sponsorsLOGOS/patro-38.png"
import patro39 from "./sponsorsLOGOS/patro-39.png"
import patro40 from "./sponsorsLOGOS/patro-40.png"
import patro41 from "./sponsorsLOGOS/patro-41.png"
import patro42 from "./sponsorsLOGOS/patro-42.png"
import logolp from "./sponsorsLOGOS/logo-life-pro-nutrition.svg"
import logofran from "./sponsorsLOGOS/logo-fran-toro.svg"
import logopbo from "./sponsorsLOGOS/pbo.svg"
import logonm from "./sponsorsLOGOS/logonm.svg"

const Sponsors = () => {
  
  return (
    <section className="sponsors">
      <h2>FIT Experience FEST es presentado por:</h2>
      <div className="creators__container">
        <img
          src={logolp}
          alt="Life Pro Nutrition" title="Life Pro Nutrition"
          className="logolp"
        />
         <img
          src={logonm}
          alt="Nutrimarket" title="Nutrimarket"
          className="logolp"
        />
       <img
          src={logofran}
          alt="Francisco Mederos" title="Francisco Mederos"
          className="logolp"
        />
        <img
          src={logopbo}
          alt="Team BPO" title="Team BPO"
          className="logolp"
        />
      </div>

      <h2>COLABORACIONES</h2>
      <div className="sponsors__brands-container">
        <img src={patro1} alt="Hercules Sport Center" title="Hercules Sport Center" className="sponsors__logos"/>
        <img src={patro2} alt="Luis Melo Trainer" title="Luis Melo Trainer" className="sponsors__logos"/>       
        <img src={patro4} alt="Kingdom Gym" title="Kingdom Gym" className="sponsors__logos op4"/>
        <img src={patro5} alt="Animal Gym" title="Animal Gym" className="sponsors__logos op4"/>
        <img src={patro6} alt="Begym" title="Begym" className="sponsors__logos op4"/>
        <img src={patro7} alt="Impacto Caletillas" title="Impacto Caletillas" className="sponsors__logos"/>
        <img src={patro8} alt="" className="sponsors__logos op4"/>
        <img src={patro9} alt="La Ruta Gril" title="La Ruta Gril" className="sponsors__logos op4"/>
        <img src={patro10} alt="Ático" title="Ático" className="sponsors__logos"/>
        <img src={patro11} alt="Urban 180" title="Urban 180" className="sponsors__logos"/>
        <img src={patro12} alt="" className="sponsors__logos"/>
        <img src={patro13} alt="Casbrad" title="Casbrad" className="sponsors__logos"/>
        <img src={patro14} alt="Cosa Rica" title="Cosa Rica" className="sponsors__logos op6"/>
        <img src={patro15} alt="" className="sponsors__logos op6"/>
        <img src={patro16} alt="Mostazza" title="Mostazza" className="sponsors__logos op4"/>
        <img src={patro17} alt="" className="sponsors__logos"/>
        <img src={patro18} alt="Naefit" title="Naefit" className="sponsors__logos"/>
        <img src={patro19} alt="Gekko" title="Gekko" className="sponsors__logos"/>
        <img src={patro20} alt="" className="sponsors__logos op4"/>
        <img src={patro21} alt="Urban Fitness" title="Urban Fitness" className="sponsors__logos"/>
        <img src={patro22} alt="Makeadito" title="Makeadito" className="sponsors__logos"/>
        <img src={patro23} alt="" className="sponsors__logos"/>
        <img src={patro25} alt="Lakkery by Béguin" title="Lakkery by Béguin" className="sponsors__logos"/>
        <img src={patro26} alt="Congelados Tenepalma" title="Congelados Tenepalma" className="sponsors__logos"/>
        <img src={patro27} alt="Marmoles Gestoso" title="Marmoles Gestoso" className="sponsors__logos"/>
        <img src={patro28} alt="Starfit" title="Starfit" className="sponsors__logos"/>
        <img src={patro29} alt="Atlax" title="Atlax" className="sponsors__logos"/>
        <img src={patro30} alt="Maram Fitness" title="Maram Fitness" className="sponsors__logos"/>
        <img src={patro31} alt="Nomad" title="NOmad" className="sponsors__logos"/>
        <img src={patro32} alt="Testa" title="Testa" className="sponsors__logos"/>
        <img src={patro33} alt="Ayalguna" title="Ayalguna" className="sponsors__logos op6"/>
        <img src={patro34} alt="Passarella Academia" title="Passarella Academia" className="sponsors__logos op6"/>
        <img src={patro35} alt="Pisercons Varela" title="Pisercons Varela" className="sponsors__logos"/>
        <img src={patro36} alt="BB" title="BB" className="sponsors__logos"/>
        <img src={patro37} alt="El Corte Vacuno Selección" title="El Corte Vacuno Selección" className="sponsors__logos "/>
        <img src={patro38} alt="Charlie Terapeuta" title="Charlie Terapeuta" className="sponsors__logos "/>
        <img src={patro39} alt="Desokupa Ahora" title="Desokupa Ahora" className="sponsors__logos "/>
        <img src={patro40} alt="Fullfitness 77" title="Fullfitness 77" className="sponsors__logos op6 "/>
        <img src={patro41} alt="Kingsnutricion" title="Kingsnutricion" className="sponsors__logos op6 "/>
        <img src={patro42} alt="Omicron" title="Omicron" className="sponsors__logos op6 "/>
      </div>
    </section>
  );
};

export default Sponsors;
