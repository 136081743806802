import "./information.css";
import Button from "../button/button";
import { Link } from "react-router-dom";

const Information = () => {
  return (
    <section className="information" id="information">
      <div className="information__container">
        <div className="information__titles">
          <h2>
            Acerca de <span>Fit Experience Fest Tenerife</span>
          </h2>
        </div>
        <p className="info__description">
        Festival para unir el deporte multifacético, con una experiencia enriquecedora y dinámica que no solo celebra el deporte, sino también fortalece el sentido de la comunidad y bienestar entre los participantes de todas las edades.
        </p>
        <div className="information__titles">
          <h2>
            <span>Invitados</span>
          </h2>
        </div>
        <div className="information__content-container">
    
          <div className="information__content">        
            <p>
            <span className="resalta">Jorge Tabet</span><br />
            Deportista Profesional de Culturismo, empresario, motivador.
            </p>
          </div>
          <div className="information__content">
            <p>
            <span className="resalta">Sandra Padrino</span><br />
            Influencer, emprendedora, empresaria.
            </p>
          </div>
          <div className="information__content">           
            <p>
            <span className="resalta">Nauzet Trujillo</span><br />
            Campeón del Mundo de Muay Thai.
            </p>
          </div>
          <div className="information__content">           
            <p>
            <span className="resalta">Miguel Knario</span><br />
            Campeón de España 2021 categoría -105.
            </p>
          </div>
          <div className="information__content">           
            <p>
            <span className="resalta">Doctor José Abellan</span><br />
            Cardiólogo, deportista, locutor de podcast, divulgador de salud.
            </p>
          </div>
          <div className="information__content">           
            <p>
            <span className="resalta">Fit Willy</span><br />
            Fitwilly Culturista, empresario, motivador.
            </p>
          </div>
          <div className="information__content">           
            <p>
            <span className="resalta">Julian Galarzo</span><br />
            Deportista, empresario, influencer, motivador.
            </p>
          </div>
          <div className="information__content">           
            <p>
            <span className="resalta">Mireia Figueras</span><br />
            Influencer, empresaria, coach motivacional.
            </p>
          </div>
          <div className="information__content">           
            <p>
            <span className="resalta">Sergio Espinar</span><br />
            Nutricionista, Farmacéutico y Formulador.
            </p>
          </div>
          <div className="information__content">           
            <p>
            <span className="resalta">Maria Jose Pérez</span><br />
            Jugadora selección Española, Campeona de España y Copa Reina.
            </p>
          </div>
          <div className="information__content">           
            <p>
            <span className="resalta">Moha Ramos</span><br />
            Jugador Oficial C.D. Tenerife.
            </p>
          </div>
        </div>
        <div className="InfoButton__container">
          <Button text={"Evento finalizado"} />
          <Link to={"/Fit-Experience-Fest.pdf"} target="blank" className="masinfo"><Button text={"Mas información"} /></Link>
        </div>
      </div>
    </section>
  );
};

export default Information;
