import "./footer.css";

const Footer = () => {
  return (
    <section className="footer">
      <div className="footer__brand">
        <div className="footer__brands-img">
          <img
            src="../../../img/ofi1.png"
            className="SpeedFest__Logo-footer "
          />
          <img
            src="../../../img/ofi2.png"
            className="SpeedFest__Logo-footer "
          />
         <img
            src="../../../img/ofi3.png"
            className="SpeedFest__Logo-footer  "
          />
              <img
            src="../../../img/ofi4.png"
            className="SpeedFest__Logo-footer  "
          />
               <img
            src="../../../img/ayu-sc.png"
            className="SpeedFest__Logo-footer  "
          />
        </div>  
      
      </div>
      <div className="footer__menus">
        <menu className="footer__about">
          <h3>Dirección</h3>
          <li><strong className="resalta">PARQUE MAR&Iacute;TIMO CESAR MANRIQUE</strong> AVENIDA LA CONSTITUCI&Oacute;N, 5 </li>
          <li><strong className="resalta">38005</strong> SANTA CRUZ DE TENERIFE</li>
          <li><strong className="resalta">D&Iacute;A:</strong> 14 DE SEPTIEMBRE DE 2024</li>
<li><strong className="resalta">HORA: </strong> 14:00 A 23:00 HORAS</li>
<li><strong className="resalta">AFORO: </strong> 2000 PERSONAS</li>
<li><strong className="resalta">EDAD: </strong> 14 A&Ntilde;OS</li>
<li><strong className="resalta">DRESS CODE</strong> FITNESS POOL AND ELEGANT PARTY</li>
<li className="telegram"> <a target="_blank" href="https://t.me/+2ByEZ-zF_9JkMTg0"><img alt="telegram" src="../../../img/telegram-icon.svg"/>
<span>Grupo Telegram</span></a>    
</li>
<span className="subtelegram">Únete a nuestro Telegram para actividades y consultas del evento</span>

        </menu>
      
      </div>
    </section>
  );
};

export default Footer;
