import "./infoDEV.css";
import { Link, useLocation } from "react-router-dom";

const InfoDev = () => {
  return (
    <section className="infoDev">
      <div className="developer__info">
      <Link to={"/autorizacion-menores.pdf"} target="blank">Autorización menores</Link>
      <Link to={"/aviso"} >Aviso Legal</Link>
      <Link to={"/condiciones"} >Condiciones generales</Link>

      </div>
    </section>
  );
};

export default InfoDev;
