import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./headerMobile.css";

const HeaderMobile = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="headerMobile">
      <Link to={"/"}>
        <img
          src="../../../../img/logo-life-pro-nutrition.svg"
          className="logo__headerMobile"
        />
      </Link>
      <nav className="navBar__Mobile">
        <ul className={`menu__mobile ${isMenuOpen ? "show" : ""}`}>
          <li className="menuMobile__list-items"></li>
          <li className="menuMobile__list-items">
            <Link to={"/Fit-Experience-Fest.pdf"} target="blank">
              Mas información
            </Link>
          </li>
        </ul>
        <button className="navBar__mobile-btn" onClick={handleMenuClick}>
          <img
            src="../../../../img/flechaAbajo.png"
            className={`menu__btn-img ${isMenuOpen ? "rotate" : ""}`}
          />
        </button>
      </nav>
    </header>
  );
};

export default HeaderMobile;
